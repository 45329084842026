export const routes = {
    // Public
    HOME: "/",
    ABOUT: "/about",
    CONTACT: '/contact',
    LAUNCH_DIGITAL: "/launch-digital",
    TRY_IT_FREE: '/try-it-free',

    FLAWLESS: "/flawless-pm",
    EXPERT_ADVISORY: "/expert-advisory",
    BENEFITS: "/benefits",
    CAREERS: '/careers',
    ONBOARDING: '/hws-onboarding',
    INTEGRATIONS: '/integrations',
    TAILORED_SERVICES: '/tailored-services',

    DIGITAL_HR: '/digital-hr',
    WEBINARS: '/webinars',
    PARTNERS: '/partners',

    PRIVACY_POLICY: '/privacy-policy',
    PRESS: '/press'
}