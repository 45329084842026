import { useEffect } from 'react';

function ScrollToTop({ history }) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        });
        return () => {
            unlisten();
        }
    }, [history]);

    useEffect(() => {
        if (history.location.search === '?products') {
            window.scrollTo({
                top: document.getElementById('products')?.offsetTop ?? 0,
                behavior: "smooth"
            });
        }
    });

    return (null);
}

export default ScrollToTop;