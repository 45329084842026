import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

// general components
import Navbar from '../../../components/general/Navbar'

// components
import { Grid, Container, Image } from 'semantic-ui-react'
import TitleDescription from '../../../components/general/TitleDescription';
import RequestDemoForm from './RequestDemoForm';

import { initGA, GAPageView } from '../../../index'

export default function TryItFree() {
    const { t } = useTranslation();
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    return (
        <Container>
            <Navbar />

            <TitleDescription
                title={t('content.launch.title')}
                description={<strong>{t('content.launch.description')}</strong>}
            />


            <Grid>
                <Grid.Row centered>
                    <Grid.Column mobile={16} tablet={3} computer={4} className="d-sm-none">
                        <Image src="/images/HRIIS_Login_1.svg" />
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={10} computer={8}>
                        <div className="box light form mx-auto">
                            <RequestDemoForm />
                        </div>
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={3} computer={4}>
                        <Image src="/images/HRIIS_Login_2.svg" />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}
