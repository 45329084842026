import React, { useEffect } from "react";

// components
import { Container } from "semantic-ui-react";
// import Footer from '../../../components/general/Footer'
import Navbar from "../../../components/general/Navbar";

import { initGA, GAPageView } from "../../../index";

const Onboarding = () => {
    useEffect(() => {
        initGA();
        GAPageView();
    }, []);

    return (
        <Container>
            <Navbar />

            <h1>Ochrana osobných údajov</h1>

            <ul className="nested-ul">
                <li>
                    <h3>1. Úvod</h3>
                </li>
                <ul>
                    <li>
                        1.1. Zaviazali sme sa chrániť súkromie návštevníkov
                        našich stránok a používateľov našich služieb.
                    </li>
                    <li>
                        1.2. Tieto pravidlá platia všade tam, keď sme správcami
                        osobných údajov návštevníkov našich webových stránok a
                        používateľov našich služieb, inými slovami, kde určujeme
                        účel a význam spracovania týchto osobných údajov.
                    </li>
                    <li>
                        1.3. Pri prvej návšteve našej webovej stránky vás
                        žiadame o súhlas s používaním súborov cookies v súlade s
                        podmienkami týchto pravidiel.
                    </li>
                    <li>
                        1.4. V týchto zásadách “my”, “nám” a “náš” sa označuje
                        Epox Plus s.r.o. Pre viac informácii o nás, si pozrite
                        Sekciu 12.
                    </li>
                </ul>

                <li>
                    <h3>2. Ako používame osobné dáta</h3>
                </li>
                <ul>
                    <li>2.1. V tejto časti 2 sme stanovili:</li>
                    <ul>
                        <li>
                            2.1.1. všeobecné kategórie osobných údajov, ktoré
                            spracovávame;
                        </li>
                        <li>
                            2.1.2. zdroj a kategóriu údajov, v prípade dát,
                            ktoré sme neobdŕžali priamo od Vás;
                        </li>
                        <li>
                            2.1.3. účel, na ktorý osobné údaje spracovávame;
                        </li>
                        <li>2.1.4. právne základy ich spracovania.</li>
                    </ul>

                    <li>
                        2.2. Môžeme spracovávať údaje o používaní našich
                        webových stránok a služieb (údaje o používaní). Údaje o
                        používaní môžu obsahovať vašu IP adresu, geografickú
                        polohu, webový prehliadač a jeho verziu, operačný
                        systém, referenčný zdroj, dĺžku návštevy, zobrazenia
                        stránok a navigačné cesty stránok, rovnako aj informácie
                        o časovaní, frekvencii a vzorci používania našej služby.
                        Zdrojom údajov o používaní je služba Google Analytics.
                        Tieto údaje o používaní môžu byť spracované na účely
                        analýzy používania webových stránok a služieb. Právnym
                        základom tohto spracovania je súhlas a naše oprávnené
                        záujmy, t. j. monitorovanie a zlepšovanie našich
                        webových stránok a služieb.
                    </li>
                    <li>
                        2.3. Môžeme spracovávať informácie, ktoré zverejňujete
                        na našich webových stránkach alebo prostredníctvom
                        údajov, ktoré použijete pri využívaní našich služieb.
                        Uverejnené údaje môžu byť spracované na účely ich
                        uverejnenia a spravovania našich webových stránok a
                        služieb. Právnym základom tohto spracovania je súhlas.
                    </li>
                    <li>
                        2.4. Môžeme spracovávať informácie obsiahnuté v
                        akomkoľvek dotazníku (údaje z dotazov), pokiaľ ide o
                        údaje ohľadne tovaru a / alebo služieb. Právnym základom
                        tohto spracovania je súhlas.
                    </li>
                    <li>
                        2.5. Môžeme spracovávať informácie, ktoré nám poskytnete
                        za účelom odberu našich e-mailových ponúk a / alebo
                        newslettru (oznamovacie údaje). Oznamovacie údaje môžu
                        byť spracované na odosielanie príslušných upozornení a /
                        alebo newslettra. Právnym základom tohto spracovania je
                        súhlas.
                    </li>
                    <li>
                        2.6. Môžeme spracovávať informácie (kontaktné údaje)
                        obsiahnuté v alebo súvisiace s akoukoľvek komunikáciou,
                        v ktorej nám posielate kontaktné údaje. Dáta
                        korešpondencie môžu obsahovať komunikačný obsah a met
                        údaje spojené s komunikáciou. Naše webové stránky
                        vygenerujú metadáta súvisiace s komunikáciou
                        prostredníctvom kontaktných formulárov webových stránok.
                        Údaje o korešpondencii môžu byť spracované na účely
                        komunikácie s vami a vedenia záznamov. Právnym základom
                        tohto spracovania sú naše oprávnené záujmy, a to riadna
                        správa našich webových stránok a podnikania a
                        komunikácia s používateľmi a / alebo plnenie zmluvy
                        medzi vami a nami a / alebo kroky vedúce k uzavretiu
                        zmluvy.
                    </li>
                    <li>
                        2.7. Môžeme spracovať akékoľvek vaše osobné údaje
                        uvedené v týchto pravidlách, ak je to potrebné na
                        vytvorenie, uplatnenie alebo obhajobu právnych nárokov,
                        či už v súdnom konaní alebo v správnom alebo mimosúdnom
                        konaní. Právnym základom tohto spracovania sú naše
                        legitímne záujmy, a to ochrana a presadzovanie našich
                        zákonných práv, vaše zákonné práva a zákonné práva
                        iných.
                    </li>
                    <li>
                        2.8. Okrem špecifických účelov, na ktoré môžeme
                        spracovať vaše osobné údaje stanovené v tejto časti 2,
                        môžeme spracovať aj akékoľvek vaše osobné údaje, ak je
                        takéto spracovanie nevyhnutné pre splnenie zákonnej
                        povinnosti, ktorou podliehame alebo pre ochranu svojich
                        životných záujmov alebo životných záujmy inej fyzickej
                        osoby.
                    </li>
                </ul>

                <li>
                    <h3>3. Poskytovanie vašich osobných údajov iným osobám</h3>
                </li>
                <ul>
                    <li>
                        3.1. Okrem špecifických zverejnení osobných údajov
                        uvedených v tejto časti 3, môžeme zverejniť vaše osobné
                        údaje, ak je takéto zverejnenie nevyhnutné na splnenie
                        zákonnej povinnosti, ktorou sme vystavení, alebo na
                        ochranu vašich životne dôležitých záujmov alebo životne
                        dôležitých záujmov inej fyzickej osoby. Vaše osobné
                        údaje môžeme zverejniť aj vtedy, ak je takéto
                        zverejnenie nevyhnutné na zistenie, uplatnenie alebo
                        obhajobu právnych nárokov, či už v súdnom konaní alebo v
                        správnom alebo mimosúdnom konaní.
                    </li>
                </ul>

                <li>
                    <h3>4. Medzinárodné prenosy vašich osobných údajov</h3>
                </li>
                <ul>
                    <li>
                        4.1. Beriete na vedomie, že osobné údaje, ktoré odošlete
                        na zverejnenie prostredníctvom našich webových stránok
                        alebo služieb, môžu byť prístupné prostredníctvom
                        internetu na celom svete. Nemôžeme zabrániť použitiu
                        (alebo zneužitiu) takýchto osobných údajov inými
                        osobami.
                    </li>
                </ul>

                <li>
                    <h3>5. Uchovávanie a mazanie osobných údajov</h3>
                </li>
                <ul>
                    <li>
                        5.1. Táto časť 5 stanovuje naše zásady a postupy
                        uchovávania údajov, ktoré sú navrhnuté tak, aby pomohli
                        zabezpečiť dodržiavanie našich zákonných povinností v
                        súvislosti so zachovaním a vymazaním osobných údajov.
                    </li>
                    <li>
                        5.2. Osobné údaje, ktoré spracovávame na akýkoľvek účel
                        alebo účely, sa nesmú uchovávať dlhšie, než je potrebné
                        na tento účel alebo na tieto účely.
                    </li>
                    <li>5.3. Osobné údaje budeme uchovávať nasledovne:</li>
                    <ul>
                        <li>
                            5.3.1. údaje o používaní, údaje o zverejňovaní,
                            údaje z dotazov, notifikačné údaje a údaje z
                            korešpondencie sa uchovajú po dobu maximálne 10
                            rokov.
                        </li>
                    </ul>

                    <li>
                        5.4. Bez ohľadu na ostatné ustanovenia tejto časti 5 si
                        môžeme ponechať vaše osobné údaje, ak je takéto
                        uchovávanie nevyhnutné na splnenie zákonnej povinnosti,
                        ktorou sme vystavení, alebo na ochranu vašich životne
                        dôležitých záujmov alebo životných záujmov inej fyzickej
                        osoby.
                    </li>
                </ul>

                <li>
                    <h3> 6. Zmeny</h3>
                </li>
                <ul>
                    <li>
                        6.1. Tieto pravidlá môžeme z času na čas aktualizovať
                        zverejnením novej verzie na našich webových stránkach.
                    </li>
                    <li>
                        6.2. Príležitostne by ste mali túto stránku
                        skontrolovať, aby ste boli spokojní s akýmikoľvek
                        zmenami v týchto pravidlách.
                    </li>
                    <li>
                        6.3. O zmenách týchto pravidiel vás môžeme informovať
                        e-mailom.
                    </li>
                </ul>

                <li>
                    <h3>7. Vaše práva</h3>
                </li>
                <ul>
                    <li>
                        7.1. V tejto časti 7 sme zhrnuli práva, ktoré máte podľa
                        zákona o ochrane údajov. Niektoré práva sú zložité a nie
                        všetky podrobnosti boli zahrnuté do našich súhrnov.
                        Preto by ste si mali prečítať príslušné zákony a
                        usmernenia regulačných orgánov o úplnom vysvetlení
                        týchto práv.
                    </li>
                    <li>
                        7.2. Vaše základné práva podľa zákona o ochrane údajov
                        sú:
                    </li>
                    <ul>
                        <li>7.2.1. právo na prístup;</li>
                        <li>7.2.2. právo na opravu;</li>
                        <li>7.2.3. právo vymazanie;</li>
                        <li>7.2.4. právo na obmedzenie spracovania;</li>
                        <li>7.2.5. právo namietať proti spracovaniu;</li>
                        <li>7.2.6. právo na údajov;</li>
                        <li>7.2.7. právo sťažovať sa dozornému orgánu; a</li>
                        <li>7.2.8. právo odobrať súhlas.</li>
                    </ul>

                    <li>
                        7.3. Máte právo potvrdiť, či môžeme spracovať vaše
                        osobné údaje a kde k nim pristupujeme spolu s ďalšími
                        informáciami. Tieto doplňujúce informácie zahŕňajú
                        podrobnosti o účeloch spracovania, kategóriách
                        dotknutých osobných údajov a osobách, ktoré sú
                        príjemcami osobných údajov. Poskytovanie práv a slobôd
                        ostatných nie je ovplyvnené, poskytneme vám kópiu vašich
                        osobných údajov. Prvá kópia bude poskytnutá bezplatne,
                        ale ďalšie kópie môžu byť spoplatnené primeraným
                        poplatkom. O svoje osobné údaje môžete požiadať na
                        prostredníctvom: eduard.popovic@hriis.io
                    </li>
                    <li>
                        7.4. Máte právo na opravu akýchkoľvek nepresných
                        osobných údajov a s ohľadom na účely spracovania, ak ste
                        nám poskytli nepresné alebo chybné údaje.
                    </li>
                    <li>
                        7.5. Za určitých okolností máte právo na vymazanie
                        vašich osobných údajov bez zbytočného odkladu. Medzi
                        tieto okolnosti patrí: osobné údaje už nie sú potrebné k
                        účelu, na ktoré boli zhromaždené alebo inak spracované;
                        odoberiete súhlas so spracovaním na základe súhlasu;
                        máte námietky voči spracovaniu podľa určitých pravidiel
                        platného zákona o ochrane údajov; spracovanie je určené
                        na účely priameho marketingu; a osobné údaje boli
                        neoprávnene spracované. Existujú však výnimky práva na
                        vymazanie. Všeobecné výnimky platia tam, kde je potrebné
                        spracovanie: na uplatňovanie práva na slobodu prejavu a
                        informácií; dodržiavanie zákonnej povinnosti; alebo na
                        vznik, výkon alebo obranu právnych nárokov.
                    </li>
                    <li>
                        7.6. Za určitých okolností máte právo obmedziť
                        spracovanie vašich osobných údajov. Tieto okolnosti sú:
                        spochybňujete presnosť osobných údajov; spracovanie je
                        nezákonné, ale vylučujete vymazanie; už nepotrebujeme
                        osobné údaje na účely nášho spracovania, ale požadujete
                        osobné údaje na zriadenie, uplatnenie alebo obhajobu
                        právnych nárokov; a vy ste namietali proti spracovaniu,
                        až do overenia tejto námietky. Ak je spracovanie na
                        tomto základe obmedzené, môžeme naďalej uchovávať vaše
                        osobné údaje. Budeme ich však spracovávať inak: s vaším
                        súhlasom; na vznik, výkon alebo obranu právnych nárokov;
                        na ochranu práv inej fyzickej alebo právnickej osoby;
                        alebo z dôvodov dôležitého verejného záujmu.
                    </li>
                    <li>
                        7.7. Máte právo vzniesť námietky voči spracovaniu vašich
                        osobných údajov z dôvodu vašej konkrétnej situácie, ale
                        iba v rozsahu, v akom to právny základ povoľuje.
                        Spracovanie je nevyhnutné pre: plnenie úlohy vykonávanej
                        vo verejnom záujme alebo pri výkone akéhokoľvek úradného
                        orgánu, ktorý nám bol zverený; alebo na účely
                        oprávnených záujmov, ktoré sledujeme na základe našej
                        alebo tretej strany. Ak takúto námietku urobíte a
                        nemôžeme preukázať presvedčivé oprávnené dôvody na
                        spracovanie, ktoré prevyšujú vaše záujmy, práva a
                        slobody, alebo spracovanie je na vytvorenie, výkon alebo
                        obranu právnych nárokov, prestaneme spracovávať osobné
                        informácie.
                    </li>
                    <li>
                        7.8. Máte právo namietať proti spracovaniu vašich
                        osobných údajov na účely priameho marketingu (vrátane
                        profilovania). Ak takúto námietku urobíte, prestaneme
                        spracovávať vaše osobné údaje na tento účel.
                    </li>
                    <li>
                        7.9. Máte právo vzniesť námietky voči spracovaniu vašich
                        osobných údajov na vedecké alebo historické výskumné
                        účely alebo na štatistické účely z dôvodov týkajúcich sa
                        vašej konkrétnej situácie, pokiaľ nie je spracovanie
                        nevyhnutné na úlohy vykonané z dôvodov verejného záujmu.
                    </li>
                    <li>
                        7.10. Právny základ pre spracovanie vašich osobných
                        údajov je:
                    </li>
                    <ul>
                        <li>7.10.1. súhlas; alebo</li>
                        <li>
                            7.10.2. ak je spracovanie nevyhnutné pre plnenie
                            zmluvy, ktorej ste zmluvnou stranou, alebo na
                            vykonanie krokov na základe vašej žiadosti pred
                            uzatvorením zmluvy,
                        </li>
                        <li>
                            7.10.3. a takéto spracovanie sa uskutočňuje
                            automatizovanými prostriedkami, máte právo obdŕžať
                            vaše osobné údaje v štruktúrovanom, bežne používanom
                            a strojom čitateľnom formáte. Toto právo sa však
                            neuplatňuje, ak by nepriaznivo ovplyvnilo práva a
                            slobody iných.
                        </li>
                    </ul>

                    <li>
                        7.11. Ak sa domnievate, že naše spracovanie vašich
                        osobných údajov porušuje zákony na ochranu údajov, máte
                        zákonné právo podať sťažnosť orgánu dohľadu zodpovednému
                        za ochranu údajov. Môžete tak urobiť v členskom štáte EÚ
                        vášho obvyklého pobytu, miesta výkonu práce alebo miesta
                        údajného porušenia.
                    </li>
                    <li>
                        7.12. V rozsahu, v akom je právny základ pre spracovanie
                        vašich osobných údajov odsúhlasený, máte právo
                        kedykoľvek odobrať tento súhlas.
                    </li>
                    <li>
                        7.13. Môžete uplatniť akékoľvek vaše práva vo vzťahu k
                        vašim osobným údajom prostredníctvom písomného
                        oznámenia.
                    </li>
                </ul>

                <li>
                    <h3>8. O cookies</h3>
                </li>
                <ul>
                    <li>
                        8.1. Cookie je súbor obsahujúci identifikátor (reťazec
                        písmen a čísel), ktorý posiela webový server do webového
                        prehliadača a je uložený prehliadačom. Identifikátor sa
                        potom odosiela späť na server vždy, keď prehliadač
                        požaduje od servera stránku.
                    </li>
                    <li>
                        8.2. Súbory cookies môžu byť buď “trvalé” súbory cookies
                        alebo súbory “relácie”: trvalý súbor cookie bude uložený
                        vo webovom prehliadači a zostane platný až do
                        stanoveného dátumu vypršania platnosti, pokiaľ nie je
                        pred dátumom vypršania platnosti vymazaný používateľom;
                        cookies relácie vypršia na konci relácie používateľa,
                        keď je webový prehliadač zatvorený.
                    </li>
                    <li>
                        8.3. Súbory cookies zvyčajne neobsahujú žiadne
                        informácie, ktoré osobne identifikujú používateľa, ale
                        osobné informácie, ktoré ukladáme, môžu byť prepojené s
                        informáciami uloženými v cookies a získanými z nich.
                    </li>
                </ul>

                <li>
                    <h3>9. Cookies, ktoré používame</h3>
                </li>
                <ul>
                    <li>9.1. Cookies používame na nasledujúce účely:</li>
                    <ul>
                        <li>
                            9.1.1. overovanie – súbory cookies používame na vašu
                            identifikáciu pri návšteve našich webových stránok a
                            počas navigácie na našich webových stránkach;
                        </li>
                        <li>
                            9.1.2. analýza – používame súbory cookies, ktoré nám
                            pomáhajú analyzovať používanie a výkonnosť našich
                            webových stránok a služieb; a
                        </li>
                        <li>
                            9.1.3. súhlas cookies – súbory cookies používame na
                            ukladanie vašich preferencií k používaniu súborov
                            cookies pri prehľadání webovej stránky
                        </li>
                    </ul>
                </ul>

                <li>
                    <h3>10. Cookies používané našimi poskytovateľmi služieb</h3>
                </li>
                <ul>
                    <li>
                        10.1. Naši poskytovatelia služieb používajú súbory
                        cookies a tieto cookies môžu byť uložené vo vašom
                        počítači pri návšteve našich webových stránok.
                    </li>
                    <li>
                        10.2. Na analýzu používania našich webových stránok
                        používame službu Google Analytics. Služba Google
                        Analytics zhromažďuje informácie o používaní webových
                        stránok prostredníctvom súborov cookies. Zhromaždené
                        informácie týkajúce sa našich webových stránok sa
                        používajú na vytváranie prehľadov o používaní našich
                        webových stránok. Pravidlá ochrany osobných údajov
                        spoločnosti Google sú k dispozícii na tejto stránke.
                    </li>
                    <li>
                        10.3. Používame Facebook Pixel na analýzu použitia
                        našich webových stránok. Táto služba používa súbory
                        cookies na zabezpečenie toho, aby sa naše reklamy
                        zobrazovali správnym ľuďom a vytvárali reklamné
                        publikum. Môžete si pozrieť pravidlá ochrany osobných
                        údajov tohto poskytovateľa služieb na tejto adrese.
                    </li>
                </ul>

                <li>
                    <h3>11. Správa súborov cookies</h3>
                </li>
                <ul>
                    <li>
                        11.1. Väčšina prehliadačov vám umožňuje odmietnuť
                        prijatie súborov cookies a odstrániť súbory cookies.
                        Metódy na ich správu sa líšia v závislosti od
                        prehliadača a verzie prehliadača. Môžete však získať
                        aktuálne informácie o blokovaní a mazaní súborov cookies
                        prostredníctvom týchto odkazov:
                    </li>
                    <ul>
                        <li>11.1.1. Chrome</li>
                        <li>11.1.2. Firefox</li>
                        <li>11.1.3. Opera</li>
                        <li>11.1.4. Internet Explorer</li>
                        <li>11.1.5. Safari</li>
                        <li>11.1.6. Edge</li>
                    </ul>

                    <li>
                        11.2. Blokovanie všetkých súborov cookies bude mať
                        negatívny vplyv na použiteľnosť mnohých webových
                        stránok.
                    </li>
                    <li>
                        11.3. Ak zablokujete cookies, nebudete môcť používať
                        všetky funkcie našich webových stránok.
                    </li>
                </ul>

                <li>
                    <h3>12. Podrobnosti o nás</h3>
                </li>
                <ul>
                    <li>
                        12.1. Túto webovú stránku vlastní a prevádzkuje
                        spoločnosť SODONBOS s.r.o.
                    </li>

                    <li>
                        12.2. Na Slovensku sme registrovaní registračným číslom
                        (IČO) 53686829 sídlime na adrese Michalovská 66/5 073 01
                        Sobrance .
                    </li>

                    <li>
                        12.3. Našim miestom podnikania je Michalovská 66/5 073
                        01 Sobrance.
                    </li>

                    <li>12.4. Môžete nás kontaktovať:</li>
                    <ul>
                        <li>12.4.1. poštou na uvedenú poštovú adresu;</li>
                        <li>
                            12.4.2. vyplnením kontaktného formulára na našej
                            webovej stránke;
                        </li>
                        <li>
                            12.4.3. telefonicky, na číslo kontaktu uverejnené na
                            našej webovej stránke; alebo
                        </li>
                        <li>
                            12.4.4. e-mailom, použitím e-mailovej adresy
                            uverejnenej na našej webovej stránke.
                        </li>
                    </ul>
                </ul>

                <li>
                    <h3>13. Úradník pre ochranu údajov</h3>
                </li>
                <ul>
                    <li>
                        13.1. Kontaktné údaje nášho správcu údajov: Eduard
                        Popovič eduard.popovic@hriis.io
                    </li>
                </ul>
            </ul>

            {/* <Footer /> */}
        </Container>
    );
};

export default Onboarding;
