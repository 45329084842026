import request from "umi-request";
import Cookies from "js-cookie";
import { authService } from "../ServiceAuth";

async function APICall(config, accessToken){

    let headers = {};
    if( config.private ){
        // const accessToken = await authService.getAccessToken();
        headers = {
            Authorization: `Bearer ${accessToken}`,
            'Accept-Language': config.language || 'en'
        };
    } else {
        headers = {
            'Accept-Language': config.language || 'en'
        };
    }

    return await request(config.url, {
        method: config.method,
        data: config.data,
        getResponse: true,
        headers: headers,
    }).then((response) => {
        return {
            status: response.response.status,
            response: response.data 
        };
    }).catch((error) => {
        if( error.response ){
            return {
                status: error.response.status,
                response: error.data
            };
        }
    })
}

/*
    Example USAGE:
    
    async function getUserProfile(){
        return await APIRequest({
            url: .../login/,  // API.LOGIN
            method: 'POST', // POST, PUT, PATCH, DELETE
            data: {
                "username": "admin",
                "password": "admin"
            },
            private: false // by default APIRequest is Private, if you want to use public use false value
        });
    }

    Response on Success:
    {
        status: 200, // or other success codes
        response: {...} // as data response object that could contain data or other type of information
    }

    Response on Failure:
    {
        status: 400, // or other failure codes
        response: {...} // as data response object, that could contain error messages
    }

*/

export async function APIRequest(config) {
    /*
        ** Function is responsible for calling and API using specific configuration: URL, Method and Data Object.
        ** Function is Calling private API which means that we need to send API Access Token that is valid.
    */
    let accessToken = Cookies.get("accessToken");
    let refreshToken = Cookies.get("refreshToken");
    
    let result = {};
    if( accessToken && config.private ){
        result = await APICall(config, accessToken)

        // attempt to refresh on unathorized
        if( result.status === 401 ){
            if (refreshToken) {
                const refreshResult = await authService.refreshLogin(refreshToken);
                if (refreshResult) {
                    accessToken = Cookies.get("accessToken");
                    refreshToken = Cookies.get("refreshToken");

                    // retry call:
                    result = await APICall(config, accessToken)

                }
            } else {
                console.log("Unauthorized")
                // window.location.href = routes.LOGIN
            }
        }
    } else {
        result = await APICall(config, accessToken)
    }

    return result;

}

export const URLManager = {
    url_with_id,
}

function url_with_id(endpoint_prefix, id){
    return endpoint_prefix + id + "/"
}

