import React from "react";
import { /*Link,*/ useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// components
import { /*Dropdown,*/ Grid, Image } from "semantic-ui-react";
// import { routes } from '../../store/routes';
// import LaunchDigitalHRButton from '../buttons/LaunchDigitalHRButton';

const Navbar = () => {
    // eslint-disable-next-line
    const history = useHistory();
    // eslint-disable-next-line
    const { t } = useTranslation();

    const handleRedirect = () => {
        window.location.href = "https://pulsawork.com";
    };

    return (
        <Grid
            style={{ padding: "1rem 0", marginLeft: "0", marginRight: "0" }}
            className="navbar"
        >
            <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                style={styles.first}
                className="small-navbar-logo"
            >
                <Image
                    onClick={() => handleRedirect()}
                    style={{ cursor: "pointer", maxWidth: "140px" }}
                    src="/images/PULSAWORK.svg"
                    alt="logo"
                    width="25%"
                    centered
                />
                {/* <Link to={routes.HOME + '?products'} className="link">{t('navbar.services')}</Link>
                <Link to={routes.ABOUT} className="link">{t('navbar.about')}</Link>
                <Link to={routes.WEBINARS} className="link">{t('navbar.webinars')}</Link>
                <Link to="/contact" className="link">{t('navbar.contact')}</Link> */}
            </Grid.Column>
            <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                style={styles.second}
                className="navbar-button-end"
            >
                <Image
                    onClick={() => handleRedirect()}
                    style={{ cursor: "pointer" }}
                    src="/images/PULSAWORK.svg"
                    alt="logo"
                    width="25%"
                    className="big-navbar-logo"
                    centered
                />
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} >
                    <Link to="#" className="link small-navbar-logo" style={{ margin: 0, marginRight: '2rem' }}>SIGN IN</Link>
                    <LaunchDigitalHRButton content={t('try')} route={routes.TRY_IT_FREE} />
                </div> */}
                {/* <Dropdown icon='bars' className="big-navbar-logo">
                    <Dropdown.Menu direction='left'>
                        <Dropdown.Item><Link to="#" className="link">SIGN IN</Link></Dropdown.Item>
                        <Dropdown.Item><Link to={routes.ABOUT} className="link">{t('navbar.about')}</Link></Dropdown.Item>
                        <Dropdown.Item><Link to={routes.HOME + '?products'} className="link">{t('navbar.services')}</Link></Dropdown.Item>
                        <Dropdown.Item><Link to={routes.WEBINARS} className="link">{t('navbar.webinars')}</Link></Dropdown.Item>
                        <Dropdown.Item><Link to={routes.CONTACT} className="link">{t('navbar.contact')}</Link></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
            </Grid.Column>
        </Grid>
    );
};

const styles = {
    first: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    second: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
};

export default Navbar;
