// for local testing purposes:
// const API_URL = "https://test-hriis.sodonbos.com/api";
const API_URL = "https://dev-hriis.sodonbos.com/api";
// const API_URL = "http://164.90.237.69:9000/api";
// const API_URL = "http://127.0.0.1:8000/api";

// for loading animation
export const LOADING_TIME = 250;

// tables pagination
export const PAGINATION_LIMIT = 5;

// Google API
export const googleMapsApiKey = "AIzaSyBkzqEbiNLSqnulRtInQZJDz7X-wbRWemo"
export const libraries = ["places"]

// User Management (Auth)
export const API = {
    SEND_DEMO_REQUEST_EMAIL: API_URL + "/emails/send_mail/hriis-io-request/",
    SEND_CONTACT_EMAIL: API_URL + "/emails/send_mail/hriis-io-contact/",
    SEND_WEBINAR_REGISTRATION_EMAIL: API_URL + "/emails/send_mail/webinar_registration/",
};
// days of expiration
export const COOKIE_EXPIRATION = 30;



