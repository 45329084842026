import { API } from "../config";

// services
import { APIRequest } from "./helpers/requests";

/*
 * Service functions
 */
export const emailService = {
    sendDemoRequestEmail,
    sendContactEmail,
    sendWebinarRequestEmail,
};

async function sendDemoRequestEmail(data) {
    return await APIRequest({
        url: API.SEND_DEMO_REQUEST_EMAIL,
        method: "POST",
        data: data,
        private: false,
    });
}

async function sendContactEmail(data) {
    return await APIRequest({
        url: API.SEND_CONTACT_EMAIL,
        method: "POST",
        data: data,
        private: false,
    });
}

async function sendWebinarRequestEmail(data) {
    return await APIRequest({
        url: API.SEND_WEBINAR_REGISTRATION_EMAIL,
        method: "POST",
        data: data,
        private: false,
    });
}
