import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// routes
import { routes } from "../../store/routes/index";

// components
import { Button } from "semantic-ui-react";
import { initGA, GAPageView } from '../../index'

function Error(props) {
    const { t } = useTranslation();

    useEffect(() => {
        initGA();
        GAPageView();
    }, []);


    return (
        <div className="error-page">
            <span className="center">
                <code>{props.code}</code>
                <p>{props.message}</p>
                <Link to={routes.HOME}>
                    <Button primary size="huge">
                        {t('goHome')}
                    </Button>
                </Link>
            </span>
        </div>
    );
}

export default Error;
