import React from "react";

import { Header } from "semantic-ui-react";

export default function TitleDescription(props) {
    return (
        <div className="title-section" style={{ marginTop: "0rem" }}>
            <Header className="large">{props.title}</Header>
            <p>{props.description}</p>
        </div>
    );
}
