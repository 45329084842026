const userReducer = (user = {}, action) => {
    switch (action.type) {
        case "ADD_USER":
            return {
                id: action.user.id,
                username: action.user.username,
                email: action.user.email,
                firstname: action.user.firstname,
                lastname: action.user.lastname,
                date_joined: action.user.date_joined,
            };
        case "LOGOUT_USER":
            return {};
        default:
            return user;
    }
};

export default userReducer;
