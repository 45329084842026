import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// store
import { isEmptyErrorList } from "../../../store/services/helpers/validation";
import { emailService } from "../../../store/services/ServiceEmail";
// import { routes } from "../../../store/routes/";

// components
import {
    Button,
    Form,
    Input,
    Message,
    // Select,
    TextArea,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

const RequestDemoForm = () => {
    const { t } = useTranslation();

    // state
    const [checked, setChecked] = useState(false);
    const [checkedTerms, setCheckedTerms] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [positive, setPositive] = useState(false);
    const [isSended, setIsSended] = useState(false);

    const initFormData = {
        firstname: "",
        lastname: "",
        company: "",
        total_employees: "",
        phone: "",
        email: "",
        source: "",
        message: "",
    };

    const redirectGDPR = () => {
        window.open("https://pulsawork.com/sk/privacy", "_blank");
    };

    const redirectTERMS = () => {
        window.open("https://pulsawork.com/sk/terms", "_blank");
    };

    const [formData, setFormData] = useState(initFormData);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        const result = await emailService.sendDemoRequestEmail(formData);

        if (result.status === 200) {
            setPositive(true);
            setIsSended(true);
            setFormData(initFormData);
            setChecked(false);
            setCheckedTerms(false);
        } else {
            setIsSended(true);
        }
        setProcessing(false);
    };

    return (
        <Form onSubmit={(e) => handleSubmit(e)}>
            <div className="fields-group">
                <Form.Group widths="equal">
                    <Input
                        required
                        placeholder={t("name")}
                        value={formData.firstname}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                firstname: e.target.value,
                            })
                        }
                    />
                    <Input
                        required
                        placeholder={t("surname")}
                        value={formData.lastname}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                lastname: e.target.value,
                            })
                        }
                    />
                </Form.Group>

                <Form.Group widths="equal">
                    <Input
                        required
                        placeholder={t("phone")}
                        value={formData.phone}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                phone: e.target.value,
                            })
                        }
                    />
                    <Input
                        required
                        type="email"
                        placeholder={t("workEmail")}
                        value={formData.email}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                email: e.target.value,
                            })
                        }
                    />
                </Form.Group>

                <Input
                    required
                    placeholder={t("company")}
                    value={formData.company}
                    onChange={(e) =>
                        setFormData({
                            ...formData,
                            company: e.target.value,
                        })
                    }
                />

                <Form.Group widths="equal">
                    <Form.Field
                        style={{ paddingLeft: 0, paddingRight: "0.2rem" }}
                    >
                        <Form.Select
                            required
                            clearable
                            placeholder={t("employees")}
                            options={[
                                { key: 1, value: "1-24", text: "1-24" },
                                { key: 2, value: "25-49", text: "25-49" },
                                { key: 3, value: "50-149", text: "50-149" },
                                { key: 4, value: "150-299", text: "150-299" },
                                { key: 5, value: "300-499", text: "300-499" },
                                { key: 6, value: "500-999", text: "500-999" },
                                { key: 7, value: "1000+", text: "1000+" },
                            ]}
                            value={formData.total_employees}
                            onChange={(e) =>
                                setFormData({
                                    ...formData,
                                    total_employees: e.target.value,
                                })
                            }
                        />
                    </Form.Field>

                    <Form.Field
                        style={{ paddingLeft: "0.2rem", paddingRight: 0 }}
                    >
                        <Form.Select
                            required
                            clearable
                            placeholder={t("howKnowAboutUs")}
                            options={[
                                { key: 1, value: "Linkedin", text: "Linkedin" },
                                { key: 2, value: "Facebook", text: "Facebook" },
                                { key: 3, value: "Google", text: "Google" },
                                {
                                    key: 4,
                                    value: "Odporúčanie",
                                    text: "Odporúčanie",
                                },
                                { key: 5, value: "Iné", text: "Iné" },
                            ]}
                            value={formData.source}
                            onChange={(e, { value }) =>
                                setFormData({
                                    ...formData,
                                    source: value,
                                })
                            }
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                    <TextArea
                        className="textarea-box"
                        placeholder={t("message") + " (" + t("optional") + ")"}
                        value={formData.message}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                message: e.target.value,
                            })
                        }
                    />
                </Form.Group>
            </div>

            <Form.Checkbox
                style={{ marginBottom: 0 }}
                checked={checked}
                onChange={() => setChecked(!checked)}
                label={{
                    children: (
                        <p style={{ marginBottom: 0 }}>
                            {t("gdpr")}{" "}
                            <Link
                                className="colored"
                                onClick={() => redirectGDPR()}
                            >
                                {t("gdprLink")}
                            </Link>
                        </p>
                    ),
                }}
            />

            <Form.Checkbox
                checked={checkedTerms}
                onChange={() => setCheckedTerms(!checkedTerms)}
                label={{
                    children: (
                        <p style={{ marginBottom: 0 }}>
                            {t("terms")}{" "}
                            <Link
                                className="colored"
                                onClick={() => redirectTERMS()}
                            >
                                {t("termsLink")}
                            </Link>
                        </p>
                    ),
                }}
            />

            <div
                className="full-width-center-content"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Message
                    onDismiss={() => setIsSended(false)}
                    positive={positive && isSended}
                    negative={!positive && isSended}
                    hidden={!isSended}
                    visible={isSended}
                    content={
                        positive && isSended
                            ? t("feedbacks.messageSentTry")
                            : !positive && isSended
                            ? t("feedbacks.messageError")
                            : ""
                    }
                />

                <Button
                    loading={processing}
                    disabled={
                        !isEmptyErrorList([
                            formData.firstname,
                            formData.lastname,
                            formData.company,
                            formData.total_employees,
                            formData.phone,
                            formData.email,
                            formData.source,
                        ]) ||
                        !checked ||
                        !checkedTerms ||
                        processing
                    }
                    type="submit"
                    className="button-big"
                >
                    {t("send")}
                </Button>
            </div>
        </Form>
    );
};

export default RequestDemoForm;
