// react
import React, { useEffect, useState } from "react";
import { Switch, useHistory, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { routes } from "./store/routes/index";
import withClearCache from "./store/connector/withClearCache";

// styles
import "./styles/default.css";
import "semantic-ui-css/semantic.min.css";

// Route Components:
import PublicRoute from "./components/routes/PublicRoute";
import ScrollToTop from "./components/routes/ScrollToTop";

// Public Pages
// import Home from "./pages/public/home/Home";
// import About from "./pages/public/about/About";
// import Contact from "./pages/public/contact/Contact";
// import LaunchDigital from "./pages/public/launch-digital/LaunchDigital";
// import Flawless from "./pages/public/flawless/Flawless";
// import ExpertAdvisory from './pages/public/expert-advisory/ExpertAdvisory';
// import Benefits from "./pages/public/benefits/Benefits";
// import Careers from "./pages/public/careers/Careers";
// import Webinars from "./pages/public/webinars/Webinars";
// import DigitalHR from "./pages/public/digital-hr/DigitalHR";
// import Onboarding from "./pages/public/onboarding/Onboarding";
// import Integrations from "./pages/public/integrations/Integrations";
// import Partners from "./pages/public/partners/Partners";
// import TailoredServices from "./pages/public/tailored-services/TailoredServices";
import TryItFree from "./pages/public/try-it-free/TryItFree";
import PrivacyPolicy from "./pages/public/privacy-policy/PrivacyPolicy";
// import Press from "./pages/public/press/Press";

// others
import "./store/i18n";
import Error from "./pages/public/Error";
// import CookieConsent from "react-cookie-consent";
// import { COOKIE_EXPIRATION } from './store/config'
import { initGA } from "./index";

function App() {
    const { i18n, t } = useTranslation();
    const language = useSelector((state) => state.language);
    const [initLang] = useState(language);
    const history = useHistory();

    useEffect(() => {
        i18n.changeLanguage(initLang);
    }, [i18n, initLang]);

    useEffect(() => {
        initGA();
    }, []);

    return (
        <>
            <ScrollToTop history={history} />
            <Switch>
                <PublicRoute
                    exact
                    restricted={false}
                    path={routes.HOME}
                    component={TryItFree}
                />
                {/* <PublicRoute exact restricted={false} path={routes.HOME} component={Home} />
                <PublicRoute exact restricted={false} path={routes.ABOUT} component={About} />
                <PublicRoute exact restricted={false} path={routes.CONTACT} component={Contact} />
                <PublicRoute exact restricted={false} path={routes.LAUNCH_DIGITAL} component={LaunchDigital} />
                <PublicRoute exact restricted={false} path={routes.FLAWLESS} component={Flawless} />
                <PublicRoute exact restricted={false} path={routes.EXPERT_ADVISORY} component={ExpertAdvisory} />
                <PublicRoute exact restricted={false} path={routes.BENEFITS} component={Benefits} />
                <PublicRoute exact restricted={false} path={routes.CAREERS} component={Careers} />
                <PublicRoute exact restricted={false} path={routes.WEBINARS} component={Webinars} />
                <PublicRoute exact restricted={false} path={routes.DIGITAL_HR} component={DigitalHR} />
                <PublicRoute exact restricted={false} path={routes.ONBOARDING} component={Onboarding} />
                <PublicRoute exact restricted={false} path={routes.INTEGRATIONS} component={Integrations} />
                <PublicRoute exact restricted={false} path={routes.PARTNERS} component={Partners} />
                <PublicRoute exact restricted={false} path={routes.TAILORED_SERVICES} component={TailoredServices} />*/}
                <PublicRoute
                    exact
                    restricted={false}
                    path={routes.PRIVACY_POLICY}
                    component={PrivacyPolicy}
                />
                {/* <PublicRoute exact restricted={false} path={routes.PRESS} component={Press} />  */}

                <PublicRoute restricted={false} exact path="*">
                    <Error code={"404"} message={t("feedbacks.pageNotFound")} />
                </PublicRoute>
            </Switch>
            {/* <CookieConsent
                location="bottom"
                buttonText="Súhlasím"
                cookieName="HRIIScookie"
                style={styles.cookie_container}
                buttonStyle={styles.cookie_button_style}
                contentStyle={styles.cookie_content}
                expires={COOKIE_EXPIRATION} //Number of days before the cookie expires.
                overlay
                overlayStyle={styles.overlay}
            >{t('cookies')}</CookieConsent> */}
        </>
    );
}

// eslint-disable-next-line
const styles = {
    cookie_button_style: {
        background: "var(--primary)",
        color: "var(--white)",
        fontSize: 16,
        fontWeight: "bold",
        padding: "1rem 2rem",
        margin: "0px 1rem",
    },
    cookie_container: {
        background: "var(--variant1)",
        display: "inline-block",
        padding: "1rem",
    },
    cookie_content: {
        margin: 0,
        marginBottom: "0.5rem",
        marginLeft: "1rem",
        width: "60%",
    },
    overlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
    },
};

export default withRouter(withClearCache(App));
